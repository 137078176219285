export const usePageData = (seoName: MaybeRefOrGetter<string | undefined>) => {
    const strapi = useStrapi()

    return useQuery(async () => {
        const { data, error } = await strapi.GET('/pages/{seoName}', {
            params: {
                path: {
                    seoName: toValue(seoName)!,
                },
            },
        })

        if (error) throw new Error('[usePageData] Failed to get page data', {
            cause: error,
        })

        return data.data?.attributes
    }, {
        key: () => `strapi:page-${(toValue(seoName))}`,
        enabled: () => !!toValue(seoName),
    })
}
